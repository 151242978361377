.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
}
body {
  background-color: #0071e3 !important;
}
.Btn {
  margin: 5px;
  background-color: #0071e3 !important;
}
.Btn:hover {
  background-color: #009ee5 !important;
}
.logo {
  width: 260px;
  padding: 0px 0px 0px 50px;
}
.navBar{
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
}
.leftDots {
  float: left;
  width: 200px;
}
.rightDots {
  float: right;
  width: 200px;
  margin-top: 25px;
}
.link{
  background-color: #f8f9fa !important;
  color: #0071e3 !important; 
  border: none !important;
  }
 button.link:hover{
   color: black !important;
 }
.link {
  background-color: #f8f9fa !important;
  color: gray !important;
  border: none !important;
  box-shadow: none !important;
}
button.link:hover {
  color: black !important;
  box-shadow: none!important;
}
.headers {
  color: #f6fcfe;
  text-align: center;
  padding: 50px;
}
.Btn-dropdown{    
width: fit-content;
display: contents;
}
.hrForm {
  background-color: #f4f4f4;
  margin-top: 25px;
  padding: 25px;
}
.navBarBtns {
  margin: 5px;
  padding: 0px 50px 0px 200px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .leftDots {
    display: none;
  }
  .rightDots {
    display: none;
  }
  .logo {
    width: 175px;
    padding-left: 10px;
  }
  .homePage,
  .homePage video{
    width: 100%
  }

  .navBarBtns{
    padding: 0;
  }
  
}